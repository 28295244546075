import "./_lib/modernizr";
import "../scss/application.scss";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/website";
import Home from "./_modules/home";
import Loader from "./_modules/loader";
import Lightbox from "./_modules/lightbox";
import Masonry from "./_modules/masonry";

$(function () {
  new Website();
  new Home();
  new Loader();
  new Lightbox();
  new Masonry();
});
