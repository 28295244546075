import Colcade from "colcade";

export default class Masonry {
  constructor() {
    this.initMasonry();
  }

  initMasonry() {
    var colc = new Colcade(".prints", {
      columns: ".prints__col",
      items: ".prints__item",
    });
  }
}
