export default class Home {
  constructor() {
    this.initHash();
  }

  initHash() {
    $("a[href^='#']").on("click", (e) => {
      e.preventDefault();
      const id = $(e.currentTarget).attr("href");
      const offset = $(id).offset().top - $(".js-header").outerHeight();
      $("html, body").animate({ scrollTop: offset }, 800);
    });
  }
}
