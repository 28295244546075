export default class Loader {
  constructor() {
    this.init();
    this.initRemove();
  }

  init() {
    if ($(".js-loader").length > 0) {
      this.blocked = true;
      const total = $(".js-loaderItem").length;
      const totalTimer = (total + 1) * 600;

      $(".js-loaderItem").each((i, el) => {
        const timer = (i + 1) * 600;
        setTimeout(() => {
          $(el).addClass("active");
        }, timer);
      });

      setTimeout(() => {
        $(".js-loader").removeClass("blocked");
        this.blocked = false;
      }, totalTimer);
    }
  }

  initRemove() {
    $(".js-loader").on("click", () => {
      if (!this.blocked) this.removeLoader();
    });

    $(document).on("keyup", (e) => {
      if (!this.blocked && $(".js-loader").length) this.removeLoader();
    });
  }

  removeLoader() {
    history.pushState({ url: "/home" }, null, "/home");
    $(".js-loader").addClass("hidden");
    setTimeout(() => {
      $(".js-loader").remove();
    }, 600);
  }
}
