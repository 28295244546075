import EmblaCarousel from "embla-carousel";

export default class Lightbox {
  constructor() {
    this.initLightbox();
  }

  initLightbox() {
    document.querySelectorAll(".js-openLightbox").forEach((el, i) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        const lightbox = document.querySelector(".lightbox");
        lightbox.classList.add("show");
        const embla = EmblaCarousel(lightbox.querySelector(".embla"), { loop: true, startIndex: i });
        const prevButton = lightbox.querySelector(".js-lightboxPrev");
        const nextButton = lightbox.querySelector(".js-lightboxNext");
        const closeButton = lightbox.querySelector(".js-closeLightbox");

        lightbox.querySelector(".embla__container").addEventListener("click", (e) => {
          if (e.offsetX < lightbox.offsetWidth / 2) {
            embla.scrollPrev();
          } else {
            embla.scrollNext();
          }
        });

        lightbox.querySelector(".embla__container").addEventListener("mousemove", (e) => {
          if (e.offsetX < lightbox.offsetWidth / 2) {
            lightbox.querySelector(".embla__container").style.cursor = "w-resize";
          } else {
            lightbox.querySelector(".embla__container").style.cursor = "e-resize";
          }
        });

        prevButton.addEventListener("click", embla.scrollPrev, false);
        nextButton.addEventListener("click", embla.scrollNext, false);

        closeButton.addEventListener("click", (e) => {
          e.preventDefault();
          lightbox.classList.remove("show");
          embla.destroy();
        });
      });
    });
  }
}
